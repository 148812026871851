import React, { useState, useEffect, useMemo } from 'react';
import './TeletextUI.css';

const TeletextUI = () => {
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedCol, setSelectedCol] = useState(0);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [pageCode, setPageCode] = useState('100');

  const [asciiArt, setAsciiArt] = useState('');

  useEffect(() => {
    const loadAsciiArt = async () => {
      try {
        const response = await fetch('./asciiphoto.txt');
        const text = await response.text();
        console.log(text);
        setAsciiArt(text);
      } catch (error) {
        console.error('Error loading ASCII art:', error);
      }
    };

    loadAsciiArt();
  }, []);

  const menuItems = useMemo(() => [
    {
      id: 1,
      text: "Github",
      url: "https://github.com/kocagozcem",
      pageNumber: "101"
    },
    {
      id: 2,
      text: "LinkedIn",
      url: "https://www.linkedin.com/in/cemkocagoz/",
      pageNumber: "102"
    },
    {
      id: 3,
      text: "E-posta",
      url: "mailto:kocagozcem@gmail.com",
      pageNumber: "103"
    }
  ], []);

  const openUrl = (url) => {
    window.open(url, '_blank');
  }

  const onSelect = () => {
    const selectedItem = menuItems[selectedRow];
    if (selectedItem?.url) {
      openUrl(selectedItem.url);
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          setSelectedRow((prev) => (prev > 0 ? prev - 1 : prev));
          setSelectedCol(0);
          break;
        case 'ArrowDown':
          setSelectedRow((prev) => (prev < menuItems.length ? prev + 1 : prev));
          setSelectedCol(0);
          break;
        case 'ArrowLeft':
          setSelectedCol((prev) => (prev > 0 ? prev - 1 : prev));
          break;
        case 'ArrowRight':
          setSelectedCol((prev) => (prev < 3 ? prev + 1 : prev));
          break;
        case 'Enter':
          onSelect();
          break;
        default:
          console.log(event.key)
          if (!isNaN(Number(event.key))) {
            setPageCode((prev) => `${prev.slice(1,3)}${event.key}`)
          }
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [selectedRow, menuItems, onSelect]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="teletext-container">
      <div className="teletext-header">
        <span className="page-number">P{pageCode}</span>
        <span className="teletext-time">
          {currentTime.toLocaleTimeString('tr-TR', {
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit'
          })}
        </span>
      </div>
      
      <div className="teletext-content">
        <div className="content-row">
          <div className="name-container">
            <h1 className="teletext-title">CEM</h1>
            <h1 className="teletext-title surname">
              {"KOCAGOZ".split('').map(letter => <span>{letter}</span>)}
            </h1>
          </div>
        </div>
        
        <div className="content-row">
          <div className="menu-section">
            {menuItems.map((item, index) => (
              <div 
                key={item.id}
                className={`teletext-row ${selectedRow === index ? 'selected' : ''}`}
                onClick={onSelect}
                onMouseEnter={() => setSelectedRow(index)}
              >
                <span className="teletext-number">{item.id}</span>
                <span className="teletext-text">
                  {item.text}
                  {item.pageNumber && (
                    <span className="page-reference">
                      {'.'.repeat(30 - item.text.length)}{item.pageNumber}
                    </span>
                  )}
                </span>
              </div>
            ))}
          </div>
          <pre>{asciiArt}</pre>
        </div>
      </div>
      
      <div className="teletext-footer">
        <p>← ↑ ↓ → tuşlariyla menüde gezebilir, "Enter" tuşu ile ilgili linke gidebilirsiniz</p>
        <div className="color-buttons">
          <span
            className={`green-button ${(selectedRow === 3 && selectedCol === 0) ? 'selected' : ''}`}
            onClick={() => openUrl('https://www.youtube.com/watch?v=n2n6C7AQbxY')}
            onMouseEnter={() => {
              setSelectedRow(3);
              setSelectedCol(0);
            }}
          >
            SPOR
          </span>
          <span
            className={`yellow-button ${(selectedRow === 3 && selectedCol === 1) ? 'selected' : ''}`}
            onClick={() => openUrl('https://www.youtube.com/watch?v=v-9F7dW6P7M')}
            onMouseEnter={() => {
              setSelectedRow(3);
              setSelectedCol(1);
            }}
          >
            SANAT
          </span>
          <span
            className={`blue-button ${(selectedRow === 3 && selectedCol === 2) ? 'selected' : ''}`}
            onClick={() => openUrl('https://tr.investing.com/currencies/usd-try')}
            onMouseEnter={() => {
              setSelectedRow(3);
              setSelectedCol(2);
            }}
          >
            EKONOMI
          </span>
          <span
            className={`red-button ${(selectedRow === 3 && selectedCol === 3) ? 'selected' : ''}`}
            onClick={() => openUrl('https://en.wikipedia.org/wiki/Teletext')}
            onMouseEnter={() => {
              setSelectedRow(3);
              setSelectedCol(3);
            }}
          >
            INFO
          </span>
        </div>
      </div>
    </div>
  );
};

export default TeletextUI;
