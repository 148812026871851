import "./App.css";
import TeletextUI from "./TeletextUI/TeletextUI";

function App() {
  const gifUrl =
    "https://media4.giphy.com/media/KfC8fUAo2w1ttdQHLx/giphy.gif?cid=790b7611a65570367363455c7ea60a9f16f5b37111ee72dc&rid=giphy.gif&ct=s";

  return (
    <div className="App">
      <TeletextUI />
      {/* <img src={gifUrl} alt="GIF" className="gif" />
      <div className="led">
        <p>Cem Döner Evi</p>
      </div> */}
    </div>
  );
}

export default App;
